import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Упс, Страница не найдена</h1>
              <p className="mt-1 text-base text-gray-500">Такой странице не существует. Пожалуйста перейдите на главную страницу.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                to="/"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                На главную
              </Link>
              <Link
                to="/contacts"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Связаться с нами
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
